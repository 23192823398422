import React from 'react';

export default ({ enabled, id }) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    if (enabled) {
      script.src = `//api.usersnap.com/load/${id}.js`;
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      if (enabled) {
        document.body.removeChild(script);
      }
    };
  }, [enabled, id]);
};
