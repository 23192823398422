import React from 'react';

export default ({ data }) => {
  const firstColData = data[0].items.slice(0, 4);
  const restData = data[0].items.slice(4);
  return (
    <div className="menu-section">
      {data[0].link ? (
        <a href={data[0].link}>
          <p>{data[0].title}</p>
        </a>
      ) : (
        <p>{data[0].title}</p>
      )}
      <div className={`menu-item double-item technologies-menu`}>
        <div class="single-item ">
          {firstColData.map((item) => (
            <a key={item.text} href={item.link}>
              {item.text}
            </a>
          ))}
        </div>
        <div class="single-item remaining-items technology-menu-margin">
          {restData.map((item) => (
            <a key={item.text} href={item.link}>
              {item.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
