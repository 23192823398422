import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import Complimentary from './components/Complimentary';
import Success from './components/Success';
import Fail from './components/Fail';
import React from 'react';
import useUsersnap from '../src/hooks/useUsersnap';
import NoMatch from './components/NoMatch';
import Expired from './components/Expired';

const isLocalHost = (hostname) =>
  !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
function App() {
  useUsersnap({ id: '498aeacf-33c7-41af-aad8-7ba4bb0c9383', enabled: !isLocalHost(window.location.hostname) });
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="docs" element={<Main />} />
        <Route path="success" element={<Success />} />
        <Route path="fail" element={<Fail />} />
        <Route path="expired" element={<Expired />} />
        <Route path="*" element={<NoMatch />} />
        <Route path="complimentary" element={<Complimentary />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
